.stats-block {
    border-width: 0.5px;
    border-style: solid;
    border-color: rgb(196, 196, 196);
}

.dygraph-annotation {
    position : absolute;
    display:inline-block;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(0, 0, 0) !important;
    border-radius: 3px;
    background-color: #1565c0;
    padding: 0px 0px 0px 0px !important;
    color: rgb(255, 255, 255) !important;
    text-align: center;
    vertical-align: middle;
    height: 18px !important;
}


.annotationCss {
    font-size: 12px !important;
    
}