@media print {
    body * {
      visibility: hidden;
    }
    #print-wrapper, #print-wrapper * {
      visibility: visible;
    }
    #print-wrapper {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
    }
    .print-design {
      break-after: page;
    }
    /* @page {
    } */
}

#print-wrapper {
  display: block;
}

.very-specific-design {
  /* width: 210mm;
  height: 297mm; */
  /* padding: 50px; */
  background: white;
  position: relative;
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center; */
  flex-shrink: 0;
  min-width: 0;
  min-height: 0;
  box-shadow: 0px 2px 38px rgba(0, 0, 0, 0.3);
}

.print-design {
  /* width: 210mm; 
  height: 297mm;  */
  /* background-color: orange; */
  border-style: solid; 
  border-color: transparent;
  border-width: 1px;
  display: block;
  break-after: page;
}

.wrapper {
  background: #666;
  width: 100%;
  height: 100%; 
  display: flex; 
  flex: 1;
  align-items: center; 
  justify-content: center; 
  min-width: 0; 
  min-height: 0;
}

.control-panel {
  background-color: white;
  width: 50%;
  height: 100%; 
  min-width: 0;
  min-height: 0; 
  overflow: auto;
}