.control-bar {
    width: 100%;
    height: 48px;
    background: #444;
    z-index: 100;
    box-shadow: 0px 2px 38px rgba(0, 0, 0, 0.2);
    padding-left: 1rem;
    display: flex;
    align-items: center;
    overflow: auto;
    overflow-y: hidden;
    overflow-x: auto;
}

.MuiFilledInput-root {
    background: rgb(232, 241, 250);
  }